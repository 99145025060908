<template>
    <main>
        <b-row class="justify-content-center py-2">
            <b-col sm='12'>
              <b-link class="brand-logo" :to="{ name: 'landing'}">
                <img class="img_logo" :src="logo" alt="logo">
              </b-link>
            </b-col>
        </b-row>
        <b-row class="justify-content-center pt-5">
            <b-col
            sm="10"
            md="9"
            lg="7"
            xl="6"
            class="text-center"
            >
                <!-- <div id="paypal-button-container"></div> -->
            </b-col>
        </b-row>
      <!-- payment modals -->
      <b-modal centered id="paymentdetailsmodal" title="Payment Αmount" v-model="paymentmodalshow" @cancel="goBack" @ok="goViva">
        <p class="lead">The payment amount is <strong>{{ this.price }}€</strong></p>
      </b-modal>
    </main>

</template>

<script>
import { loadScript } from "@paypal/paypal-js";
import {
  BRow, BCol, BLink, VBModal
} from 'bootstrap-vue'
import Swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    VBModal
  },
  data() {
    return {
      logo: require('@/assets/images/quotation/sft_logo.svg'),
      price : this.$route.query.p ? String(window.atob(this.$route.query.p)) : null,
      vivalink: 'https://pay.vivawallet.com/safetraveltoday',
      paymentmodalshow : false
    }
  },
  async mounted() {
    if (!this.price) {
      document.querySelector('footer').classList.add('d-none');
      const { value: pricing_link } = await Swal.fire({
        title: 'Payment Link',
        input: 'text',
        inputLabel: 'Your payment link',
        inputPlaceholder: 'Enter your payment link',
        showCancelButton: true,
        customClass: {
          container: 'container-class',
          popup: 'popup-class',
          header: 'header-class',
          title: 'title-swalpaypal',
          icon: 'icon-class',
          image: 'image-class',
          content: 'content-swalpaypal',
          input: 'input-class',
          actions: 'actions-class',
          confirmButton: 'confirm-button-swalpaypal',
          cancelButton: 'cancel-button-swalpaypal',
        }
      })
      
      if (pricing_link) {
        window.location.search += pricing_link;
        // show footer
        document.querySelector('footer').classList.remove('d-none');
      }else {
        // redirce to landing page
        window.location.href = './landing';
      }
    } else {
        // show payment link
        this.paymentmodalshow = true;
    }
  
    // const paypalSdk = await loadScript({
    //     'client-id': 'AV21_lEfPkiqbkMMuyGOtNsa33XtBCuajF61ms-xAL-Dyvgbit1RfYD3bPY84m4H9R59j5023qNUNU0W',
    //     currency: 'EUR',
    // });
    // paypalSdk.Buttons({
    //   style: this.style,
    //   createOrder: this.createOrder,
    //   onShippingChange: this.onShippingChange,
    //   onApprove: this.onApprove,
    //   onError: this.onError
    // }).render('#paypal-button-container');
  },
  methods: {
    goBack() {
      window.location.href = './landing';
    },
    goViva() {
      window.location.href = this.vivalink;
    }
  },
  computed: {
    // createOrder: function () {
    //   return (data, actions) => {
    //     return actions.order.create({
    //       purchase_units: [
    //         {
    //           amount: {
    //             value: this.price,
    //           },
    //         },
    //       ],
    //     });
    //   }
    // },
    // onApprove: function () {
    //   return (data, actions) => {
    //     return actions.order.capture();
    //   }
    // },
    // onShippingChange: function () {
    //   return (data, actions) => {
    //     return actions.resolve();
    //   }
    // },
    // onError: function () {
    //   return (err) => {
    //     console.error(err);
    //     window.location.href = "/landing";
    //   }
    // },
    // style: function () {
    //   return {
    //     shape: 'rect',
    //     color: 'gold',
    //     label: 'paypal',
    //     tagline: false
    //   }
    // },
  },
}
</script>
<style scoped>
  .img_logo {
    display: block;
    margin: 0 auto;
    max-width: 900px;
    width: 80%;
    height: auto;
  }
</style>